import { Player } from '../../../../server/src/room/types';

export const DICTIONARY_PLAYER: Player = {
  userId: 'DICTIONARY_PLAYER',
  color: 'black',
  isAdmin: true,
  isConnected: true,
  isInGame: true,
  username: 'DICTIONARY_PLAYER',
};
