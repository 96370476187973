export const DEFINITION_SEQUENCES = [
  'A party game to play with your friends',
  'A game to discover words you’ll never use',
  'A game exposing friends with spelling deficiencies',
  'A web game, nothing to download',
  'A game in beta development phase',
  'A game without login, no account to create',
  'A mobile browser playable game',
  'A game that lets you express your creativity',
];

export const SEQUENCE_WAIT = 1000;
